.banner_wrap{
    &.catagory{
        .banner_content{
            height: 100px;
            width: 300px;
            .description{
                display: none;
            }
        }
    }
}