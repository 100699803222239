body {
  margin: 0;
  background: #eeeff3; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  height: 100%;
}

:root {
  --containe-max-width:1100px;
  --containe-width:93%;
  --brand-color:#8340e4;
  --brand-color-dark:#6728be;
  --brand-color-darkness:#6a34b5;
  --brand-color-transparent:rgba(131,64,228,0.07);
  --error:#ff4539;
  --success:#0cc287;
  --shaddow:0px 3px 11px #0000000d;
}

.btn{
  background-color: var(--brand-color);
  border-radius: 6px;
  cursor: pointer;
  border: none;
  padding: 9px 18px;
  color: #fff;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  &:not(:first-child){
    margin-left: 8px;
  }
  &:active{
    box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.301);
  }
  &.error{
    background-color: var(--error);
  }
  &.success{
    background-color: var(--success);
  }
  &.text{
    background-color: transparent;
    color: #333;
  }
  &:disabled{
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.3;
  }
}

.form-group{
  margin-bottom: 20px;
  .form-control{
    padding: 10px;
    box-sizing: border-box;
  }
  .btn{
    width: 100%;
  }
}