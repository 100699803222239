.banner_wrap{
    .head{
        display: flex;
        justify-content: space-between;
        padding:10px 0;
        align-items: center;
        margin-top: 30px;
        .title{
            padding: 0;
            margin: 0;
            text-transform: capitalize;
        }
        .btn{
            text-transform: capitalize;
        }
    }
    ul{
        list-style: none;
        margin: 0;
        padding:10px 0;
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            &:not(:last-child){
                border-bottom: 1px #e8e8e8 dashed;
            }
            .img{
                width: 100px;
                height: 40px;
                overflow: hidden;
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100%;
                }
            }
            .description{
                width: 40%;
            }
            &.banner_item{
                display: flex;
                flex-direction: row;
                @media screen and (max-width: 767px) {
                    flex-direction: column;
                }
            }
            .banner_list_content{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                width: 100%;
                .heading{
                    width:14%;
                    font-size: 12px;
                }
                .description{
                    width:44%;
                    font-size: 12px;
                }
                .catagory{
                    font-size: 11px;
                }
            }
        }
        .action{
            min-width: 130px;
            display: flex;
            justify-content: flex-end;
            min-height: 30px;
            align-items: center;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
                button{
                    width: 100%;
                }
            }

            .edit,.delete{
                svg{
                    filter: invert(1);
                }
            }
        }
    }
    &.banners{
        .banner_content{
            input[type="text"], input[type="number"]{
                width: 160px;
                display: inline-flex;
                text-align: center;
                border: 2px dashed transparent;
                outline: none;
                background-color: transparent;
                padding: 7px;
                line-height: 1.2;
                max-width: 750px;
                border-radius: 5px;
                transition: 0.3s;
                &:focus, &:active,&:hover{
                    border: none;
                    border: 2px dashed var(--brand-color);
                }
            }
            .heading{
                margin-bottom: 20px;
                input{
                    font-size: 40px;
                }
            }
            .description{
                input{
                    font-size: 30px;
                }
            }
            
        }
    }
    .banner_content{
        width: 1200px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        @media screen and (max-width: 767px) {
            height: 250px;
        }
        &.bg_img{
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 8px;
            border: 1px dashed var(--brand-color);
        }
        .img_file{
            margin-bottom: 20px;
        }
        input[type=file]{
            width: 167px;
            padding:8px;
            border-radius: 5px;
            border: 2px dashed transparent;
        }
        input[type=file]::file-selector-button {
            margin-right: 20px;
            border: none;
            background: var(--brand-color);
            padding: 20px 30px 20px 31px;
            font-size: 20px;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            transition: background .2s ease-in-out;
        }
        
        input[type=file]::file-selector-button:hover {
            background: var(--brand-color-dark);
        }
        .img_file,.catagory,.heading,.description{
            margin-bottom: 20px;
        }
        input[type="text"],input[type="number"],select{
            padding: 14px;
            min-width: 200px;
            max-width: 300px;
            border-radius: 4px;
            border: 2px #ccc solid;
        }
    }
    &.catagory{
        .content{
            ul{
                display: flex;
                @media screen and (max-width: 767px) {
                    flex-wrap: wrap;
                }
                li{
                    background-color: #eee;
                    margin-left: 10px;
                    margin-top: 10px;
                    border-radius: 100px;
                    padding-right: 0;
                    min-height: 40px;
                    @media screen and (max-width: 767px) {
                        flex-direction: row;
                        .action{
                            margin-bottom: 0;
                        }
                    }
                    .action{
                        min-width: auto;
                        .btn{
                            font-size: 0;
                            padding: 7px;
                            border-radius: 100px;
                            line-height: 10px;
                            margin: 6px;
                            svg{
                                margin-right: -7px;
                            }
                        }
                        .error{
    
                        }
                    }
                    &:not(:hover){
                        .action{
                            @media screen and (min-width: 768px) {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &.artworks{
        .banner_content{
            background-size: 210px 180px;
            background-position: left center;
            width: 800px;
            align-items: flex-start;
            @media screen and (max-width: 767px){
                background-position: top center;
                height: 500px;
                justify-content: end;
            }
            .img_file,.catagory,.heading,.description {
                padding-left: 250px;
                @media screen and (max-width: 767px){
                    padding-left: 40px;
                }
            }
            .img_file{
                input{
                    padding: 0;
                }
            }
        }
    }
}

.banner{
    .action{
        margin-top: 20px;
        text-align: right;
        
    }
    .btn{
        padding: 14px 38px;
        font-size: 16px;
        @media screen and (max-width: 767px) {
            width: 48%;
        }
    }
}

select {
    padding:7px 12px;
    border-radius: 5px;
    /* Remove default arrow */
    /* Add custom arrow */
}

.error_msg{
    color: var(--error);
}