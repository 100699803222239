header{
    margin:0 auto;
    background: #fff;
    position: sticky;
    top: 15px;
    height: 60px;
    max-width: var(--containe-max-width);
    width: var(--containe-width);
    border-radius:7px;
    box-shadow: var(--shaddow);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 768px) {
        top: 20px;
        height: 70px;
    }
    .left{
        padding:0 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        @media screen and (min-width: 768px) {
            .expand{
                display: none;
            }
        }
    }
    .right{
        display: flex;
        height: 100%;
    }
    .link{
        min-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .profile{
        justify-content: center;
        border-left: 1px #f6f6f6 solid;
        width: 30px;
        display: flex;
        padding: 5px 17px;
        align-items: center;
        background-color: rgba(149, 0, 231, 0.02);
        border-radius: 0 0 7px 0;
        cursor: pointer;
        @media screen and (min-width: 768px) {
            width: 40px;
        }
        &:hover{
            .dropdown{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
    .dropdown{
        position: absolute;
        top: 100%;
        width: 200px;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;
        transform: translateY(20px);
        right: 0;
        ul{
            background: #fff;
            box-shadow: var(--shaddow);
            list-style: none;
            margin-top: 10px;
            padding: 15px 0;
            border-radius: 7px;
            &:before {
                content: "";
                position: absolute;
                width: 13px;
                height: 13px;
                background: #fff;
                transform: rotate(45deg);
                top: 3px;
                right: 36px;
            }
            li{
                display: flex;
                align-items: center;
                padding: 14px 20px;
                font-size: 14px;
                cursor: pointer;
                transition: 0.03s;
                justify-content: space-between;
                gap: 10px;
                &:hover{
                    box-shadow:inset 3px 0 0 0 var(--brand-color);
                    background-color: var(--brand-color-transparent);
                }
            }
        }
    }
}
.open{
    +.container{
        header{
            .expand{
                &:before{
                    position: fixed;
                    inset: 0;
                    content: "";
                    background: #000;
                    opacity: 0.4;
                }
            }
        }
    }
}