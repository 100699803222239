.modal{
    position: fixed;
    inset: 0;
    background: rgba(151, 151, 151, 0.80);
    display: flex;
    justify-content: center;
    align-items: center;
    &_container{
        background-color: #fff;
        padding: 30px;
        overflow: hidden;
        border-radius: 10px;
        min-width: 350px;
        max-width: calc(100% - 30px);
        box-sizing: border-box;
        @media screen and (max-width: 767px) {
            padding: 10px;
        }
    }
    &_foot{
        text-align: right;
        margin-top: 30px;
    }
}