#root{
  display: flex;
  height: 100%;
  // max-height: 100%;
  
  .container{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }
  .content-wrap{
    flex: 1;
    overflow: auto;
    section{
      max-width: var(--containe-max-width);
      width: var(--containe-width);
      margin:0 auto;
      .content{
        background: #fff;
        min-height: 70px;
        border-radius:7px;
        box-shadow: var(--shaddow);
      }
    }
  }
}

.dashboard_main{
  .action{
    display: none !important;
  }
}

// Login
.login_card{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 280px;
  margin: 50px auto !important;
  padding: 40px;
  .profile-img-card{
    width: 70px;
    border-radius: 100%;
    margin-bottom: 40px;
  }
  .form-control{
    width: 100%;
  }
  .btn{
    font-size: 16px;
    padding: 15px;
  }
  form{
    width: 100%;
  }
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  @keyframes spinner-border {
    to { transform: rotate(360deg); }
  }
}