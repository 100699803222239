.navigation{
    width: 230px;
    background-color: var(--brand-color);
    transition: 0.2s ease-out;
    display: flex;
    flex-direction: column;
    .brand,.expand{
        height: 70px;
        width: 70px;
        display: inline-flex;
        justify-content: center;
        position: relative;
        &:after,&:before{
            content: "";
            position: absolute;
            bottom: 0;
            border-bottom:1px #7500b6 solid;
            width: 65%;
        }
        &:before{
            border-bottom: 1px #9e00f5 solid;
            bottom: -1px;
        }
        img{
            filter: grayscale(1) invert(0) brightness(4.5);
            width: 60px;
        }
    }
    .expand{
        margin-top: auto;
        &:after,&:before{
            bottom: initial;
            top: 0;
        }
        &:before{
            top: 1px;
        }
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 20px 0;
    }
    li{
        a{
            display: flex;
            justify-content: left;
            padding: 13px 16px;
            color: #fff;
            text-decoration: none;
            align-items: center;
            gap: 18px;
            svg{
                filter: invert(1);
            }
            &:hover,&.active{
                background-color: var(--brand-color-dark);
            }
        }
        img{
            filter: grayscale(1) invert(0) brightness(4.5);
            width: 45px;
        }
    }
    &.open{
        width: 250px;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        position: fixed;
        inset: 0;
        z-index: 9;
        width: 250px;
        transform: translateX(-100%);
        &+.container{
            transition: 0.3s;
        }
        &.open{
            transform: translateX(0);
            &+.container{
                transform: translateX(250px);
            }
        }
    }
}